.errorBox {
    background-color: #ffb3b3;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
    border: solid 1px #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.errorBox .close {
    color: #000;
}

.errorBox.warn {
    background-color: #fffeb3a1;
}

.errorBox .errorContent {
    display: flex;
    align-items: center;
    gap: 10px;
}