.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: max(80%, min(700px, 100%));
    max-width: max(80%, min(400px, 100%));
    overflow-x: hidden;
    overflow-y: auto;
    margin: 3px;
}

.buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    margin-top: 20px;
    gap: 15px;
    width: 100%;
    justify-items: space-between;
}

.button {
    display: flex;
    flex-grow: 1;
    min-width: min(100%, 225px);
}

.footer {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
}