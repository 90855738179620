.editRoleList {
    padding: 5px;

    margin-bottom: 20px;
}

/* 
.editRoleList .skillTitle {
    font-size: 22px;
    border-bottom: solid 1px black;
    margin-bottom: 5px;
} */

.editRoleList .infoBoxRole {
    width: 100%;
}

.editRoleList .infoBoxRole td {
    border-bottom: solid 1px var(--var-table-border-color);
}

.editRoleList .save {
    margin-top: 10px;
}

.editRoleList .info {
    display: flex;
    gap: 3px;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    word-break: break-all;
}

.editRoleList .newRow {
    font-size: 15px;
    font-weight: 500;
    word-break: break-all;
}

.editRoleList table.infoBox {
    width: 100%;
}