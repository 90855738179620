@import url('https://fonts.googleapis.com/css?family=Open+Sans');

a {
  color: var(--var-secondary-color);
  text-decoration: none;
}

a:hover {
  color: var(--var-primary-color);
  text-decoration: underline;
}

body {
  overflow-y: hidden;
  margin: 0;
  font-family: 'Open Sans', sans-serif, monospace;
  /* font-weight: 600; */
  background-color: var(--var-background-color);
}

:root {
  --var-header-height: 60px;
  --var-footer-height: 45px;
  --var-dialogBar-height: 45px;

  --var-table-border-color: #00000036;

  --var-primary-color: #0190ce;
  --var-on-primary-color: #fff;

  --var-secondary-color: #004766;
  --var-on-secondary-color: #fff;

  --var-ai-color: #00ffff16;
  --var-background-color: #fff;
  /* --var-wrapper-width:1536px; */
  --var-wrapper-width: 1800px;
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}


.MuiBreadcrumbs-ol {
  align-items: flex-start !important;
}