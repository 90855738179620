.dialogBar {
    display: flex;
    gap: 0px;
    font-size: 15px;
    width: 100%;
    justify-content: center;
    height: var(--var-dialogBar-height);
    align-items: center;
    color: #000;
    background-color: #fff;
    align-items: center;
    border-top: solid 1px var(--var-table-border-color);
    /* transition: height 3s; */
}

.dialog {
    display: flex;
    padding: 0px 0px 0 7px;
    cursor: pointer;
    user-select: none;
    height: 45px;
    align-items: center;
    border-right: solid 1px var(--var-table-border-color);
}

.dialog:first-child {
    border-left: solid 1px var(--var-table-border-color);
}

.activeDialog {
    background-color: var(--var-primary-color);
    color: var(--var-on-primary-color);
}