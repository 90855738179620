.MultiTextChipSelect {
    display: flex;
    gap: 7px;
    justify-content: flex-start !important;
    padding: 5px !important;
    align-items: center;
    width: 100%;
}

.MultiTextChipSelect .chip {
    flex-grow: 1;
    justify-content: space-between;
}

.MultiTextChipSelect .MuiChip-label {
    width: 100%;
}