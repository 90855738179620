.itemBadge {
    display: flex;
    border: solid 1px #a0a0a0;
    border-radius: 15px;
    padding: 0px 5px;
    background-color: #ffffff90;
    font-size: 16px;
    line-height: 18px;
    cursor: initial;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .2);
}

.small {
    font-size: 13px;
    line-height: 18px;
}