.SuggestedCompetences .SuggestedCompetence {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}


.SuggestedCompetences .SuggestedCompetence .buttons {
    display: flex;
    gap: 7px;
    flex-wrap: wrap;
    justify-content: stretch;
    justify-items: stretch;
    margin: 7px 0px;
}

.SuggestedCompetences .SuggestedCompetence .buttons * {
    flex-grow: 1;
}