.competencesInput {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    box-sizing: border-box;
    align-items: stretch;
    justify-content: stretch;
    width: 100%;
    padding: 5px;
    border: solid 1px #efefef;
    border-radius: 5px;
    box-shadow: 0 5px 3px 0 rgba(0, 0, 0, .2);
}

.competencesInput .competences {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 10px;
    padding: 0px 5px;
    width: 100%;
    justify-content: flex-start;
}

.competencesInput .competence {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
    border: solid 1px #efefef;
    padding: 6px 7px;
    border-radius: 10px;
    margin: 0px 0;
    box-shadow: 0 5px 3px 0 rgba(0, 0, 0, .2);
    user-select: none;
    background-color: rgb(255, 255, 255);
    background-color: rgba(148, 213, 70, calc(var(--percent) * 1% - 20%));
}

.competencesInput.small {
    box-shadow: none;
    padding: 2px;
    gap: 5px 5px;
    border: none;
    border-radius: 0px;

}

.competencesInput.small .competence {
    font-size: 14px;
    padding: 2px 3px;
    box-shadow: none;

}

.competencesInput .competenceBoxLevel {
    display: inline;
    float: right;
    border: solid 1px #eee;
    border-radius: 15px;
    padding: 0px 5px;
    background-color: #ffffff90;
    font-size: 16px;
    line-height: 18px;
}

.newCompetenceRow {
    display: flex;
    justify-items: stretch;
    width: 100%;
}


.competencesInput .newCompetenceRow input {
    border-radius: 5px;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, .2);
    border: solid 1px rgba(0, 0, 0, 0.1);
    font-size: 15px;
    background-color: rgba(255, 255, 255, 0.3);
}

/* .competencesInput .newCompetenceRow  > div{
    flex-grow: 1;
} */