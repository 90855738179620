.FormatedTextInputContainer {
    /* display: flex; */
    /* flex-grow: 1; */
    /* width: 100%; */
}

.FormatedTextInputContainer a {
    text-decoration: underline;
}

.FormatedTextInputContainer .ql-editor {
    min-height: 90px;
}

.FormatedTextInputContainer .ql-editor.show {
    padding: 0px;
    min-height: initial;
}

/* .FormatedTextInputContainer .textInputField {
    flex-grow: 1;
    padding: 3px 5px;
} */

/* .FormatedTextInputContainer .textInputField::after {
    content: attr(data-value);
    white-space: pre-wrap;

} */