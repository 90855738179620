.helpButton {
    color: white;
    font-size: 28px;
    font-weight: bold;
}

.helpButton:hover,
.helpButton:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}