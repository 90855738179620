.employeeSuggestionOuter {
    min-height: 50px;
    min-width: 50px;
    flex-grow: 1;
    border: solid 1px #efefef;
    padding: 6px 10px;
    border-radius: 10px;
    margin: 7px 0;
    box-shadow: 0 5px 3px 0 rgba(0, 0, 0, .2);
    user-select: none;
    background-color: rgb(255, 255, 255);
    /* Weiß als Standardwert */
    background-color: rgba(148, 213, 70, calc(var(--percent) * 1% - 50%));
    /* Grün mit variabler Helligkeit */
    /* animation-name: animatetop;
        animation-duration: 0.5s; */
    font-size: 14px;
}

.employeeSuggestion {
    /* max-height: 155px; */
    max-height: 70px;
    overflow-y: hidden;
    transition: max-height 0.5s;
}

.expanded {}

.header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
    gap: 7px;
    /* box-shadow: 0 5px 3px 0 rgba(0, 0, 0, .2); */
}

.titleLine {
    display: flex;
    gap: 10px;
    align-items: center;
}

.headerInfoTable {
    border-collapse: collapse;
}

.employeeSuggestion .suggestionTitle {
    /* font-size: 24px; */

}

.employeeSuggestion .headerInfo {
    /* font-size: 14px; */
}

.employeeSuggestion .skills {
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* animation-name: animatetop; */
    animation-duration: 1s;
}

.employeeSuggestion .skillsInner {
    min-width: 140px;
    flex-basis: min-content;
    flex-grow: 1;
}

@media (max-width: 600px) {
    .employeeSuggestion .skills {
        flex-wrap: wrap;
    }
}

.expandOuter {
    margin-top: -36px;
}

.expand {
    position: relative !important;
    bottom: -26px;
    left: 50%;
    transform: translateX(-50%);
    background-color: white !important;
    border: 1px solid #e0e0e0 !important;
}

.expand:hover {
    background-color: #e0e0e0 !important;
}

.employeeSuggestionOuter.big {
    padding: 6px 20px;
}

.employeeSuggestionOuter.small {
    padding: 6px 7px;
}

.employeeSuggestionOuter.normal {}