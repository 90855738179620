.skillsInputWithoutLevel {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 5px;
    border: solid 1px black;
    border-radius: 5px;
    width: 100%;
    /* align-items:stretch; */
    justify-content: flex-start;
    padding-bottom: 10px;
}

.skillsInputWithoutLevel.small {
    box-shadow: none;
    padding: 2px;
    gap: 5px 7px;
    border: none;
    border-radius: 0px;

}

/* .skillsInputWithoutLevel .newSkillRow  > div{
    flex-grow: 1;
} */