.editCertificateList {
    padding: 5px;

    margin-bottom: 20px;
}

/* 
.editCertificateList .skillTitle {
    font-size: 22px;
    border-bottom: solid 1px black;
    margin-bottom: 5px;
} */

.editCertificateList .infoBoxCertificate {
    width: 100%;
}

.editCertificateList .infoBoxCertificate td {
    border-bottom: solid 1px var(--var-table-border-color);
}

.editCertificateList .save {
    margin-top: 10px;
}

.editCertificateList .info {
    display: flex;
    gap: 3px;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    word-break: break-all;
}

.editCertificateList .newRow {
    font-size: 15px;
    font-weight: 500;
    word-break: break-all;
}

.editCertificateList table.infoBox {
    width: 100%;
}