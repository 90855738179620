/* .editLevelList {
    padding: 10px;

} */

.editLevelListTitle {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.editLevelListTitle .edit {
    cursor: pointer;
}

.editLevelList .levelDefinitions {
    display: flex;
    gap: 17px;
    flex-direction: column;

    flex-wrap: wrap;
}

.editLevelList .levelDefinition {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: stretch;
    padding: 10px;
    border: solid 1px rgba(0, 0, 0, .12);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 5px;
}

.editLevelList .headLine {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}

.editLevelList .levelDefinition .level,
.editLevelList .levelDefinition .title,
.editLevelList .levelDefinition .description,
.editLevelList .levelDefinition .helperText {
    font-size: 16px;
    font-weight: 400;

}

/* .editLevelList .levelDefinition.editMode .level, */
.editLevelList .levelDefinition.editMode .title,
.editLevelList .levelDefinition.editMode .description,
.editLevelList .levelDefinition.editMode .helperText {
    padding: 10px;
    font-size: 16px;
    font-weight: 400;
    width: calc(100% - 20px);
}

.editLevelList .levelDefinition .textsLine {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.editLevelList .levelDefinition .title {
    font-size: 17px;
    font-weight: 600;
}

.editLevelList .levelDefinition .level {
    font-size: 17px;
    font-weight: 600;
}

.editLevelList .edit {
    cursor: pointer;
    padding: 5px;
}

.editLevelList .upDown {
    display: flex;
}

.editLevelList .upDown .arrow {
    padding: 3px 5px;
    padding-top: 4px;
    padding-bottom: 1px;
    cursor: pointer;
    border-radius: 25px;
    border: solid 2px rgba(0, 0, 0, .0);
}

.editLevelList .upDown .arrow:hover {
    border: solid 2px var(--var-secondary-color);
    color: var(--var-primary-color);
}

.editLevelList .btnRow {
    display: flex;

    flex-direction: row-reverse;
}

.editLevelList .deleteBtn {
    padding: 3px 5px;
    padding-top: 4px;
    padding-bottom: 1px;
    border-radius: 25px;
    cursor: pointer;
    border: solid 2px rgba(0, 0, 0, .0);

}

.editLevelList .deleteBtn:hover {
    border: solid 2px var(--var-secondary-color);
    color: var(--var-primary-color);
}