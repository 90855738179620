.searchBox .sugestions {
    position: relative;

    background-color: #fff;
    border-top: solid 1px black;
    border-left: solid 1px black;
    border-right: solid 1px black;

    margin-top: -1px;
    z-index: 1;
}

.searchBox .sugestion {
    border-bottom: solid 1px black;
    padding: 3px;
    cursor: pointer
}

.searchBox .sugestion:hover,
.searchBox .sugestion:focus {
    background-color: #ccc;
}

.searchBox {
    height: 22px;

    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.searchBox input {

    flex-grow: 1;
    padding: 3px;
    padding-left: 7px;
}