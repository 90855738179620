.competencesBoxTitle {
    display: inline;
    margin-right: 10px;
}

.competencesBoxLevel {
    display: inline;
    float: right;
    border: solid 1px #eee;
    border-radius: 15px;
    padding: 0px 5px;
    background-color: #ffffff90;
    font-size: 16px;
    line-height: 18px;
}