.linkInputContainer {
    display: flex;
    flex-grow: 1;
    width: 100%;
}

.linkInputContainer .linkInputField {
    flex-grow: 1;
    padding: 3px 5px;
}

.linkInputContainer .linkInputField::after {
    content: attr(data-value);
    white-space: pre-wrap;

}