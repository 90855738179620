.analyseSkillsPdfButton{
  display: inline-block;

}

.analyseSkillsPdfButton .analyseFileButton{
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
}

.analyseSkillsPdfButton #fileButton{
display: none;
}


