.textInputContainer {
    display: flex;
    flex-grow: 1;
    width: 100%;
}

.textInputContainer .textInputField {
    flex-grow: 1;
    padding: 3px 5px;
}

.textInputContainer .textInputField::after {
    content: attr(data-value);
    white-space: pre-wrap;

}