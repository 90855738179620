.entitiesPage .searchEntityBox {
    display: flex;
    font-weight: 400;
    font-size: 16px;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    align-items: center;
}


.entitiesPage .buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    height: 32px;
    overflow: hidden;
    transition: height 0.4s;
}

.entitiesPage .buttons.hidden {
    height: 0px;
    margin: 0px;
    padding: 0px;
    transition: height 0.2s;
}