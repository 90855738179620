.editSkillList {
    margin-bottom: 200px;
}

.editSkillList .skillInfo {
    display: grid;
    /* width: 100%; */
    grid-template-columns: 1fr 1fr auto;
    column-gap: 5px;
    align-items: center;
}

.editSkillList .delete {
    max-width: 100px;
}

/* 
.editSkillList .skillTitle {
    font-size: 22px;
    border-bottom: solid 1px black;
    margin-bottom: 5px;
} */


.editSkillList .infoBoxSkill {
    width: 100%;
}

.editSkillList .infoBoxSkill td {
    border-bottom: solid 1px var(--var-table-border-color);
}

.editSkillList .save {
    margin-top: 10px;
}

.editSkillList .info {
    display: flex;
    gap: 3px;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    word-break: break-word;
    border-bottom: solid 1px #00000030;
    overflow-x: hidden;
    height: 100%;
}