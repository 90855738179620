.page .title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 7px;
    margin-left: 5px;
}

.page .buttons {
    display: inline-block;
}