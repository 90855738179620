.entitiesPage .category {
    flex-grow: 1;
}

.entitiesPage .category .skillContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 10px;
    margin-bottom: 10px;
}

.entitiesPage .category .skillContainer>.BoxItem {
    margin: 0px;
}

.entitiesPage .treeSkillList {
    display: flex;
    gap: 0px 5px;
    flex-wrap: wrap;
    justify-content: left;
    pointer-events: auto;
}