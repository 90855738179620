.expandable {
    min-height: 20px;
}

.expandable,
.expandable>*>*>* {
    cursor: pointer !important;
}


.expandable.small .ql-editor.show {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* Zeilenanzahl, die angezeigt werden sollen */
    overflow: hidden;
    text-overflow: ellipsis;
}