.uploadAvatar {
    cursor: pointer;
}

.employeePicture {
    border-radius: 50%;
}

.employeePicture .uploadButton {
    display: none;
}

.employeePicture:hover .uploadButton {
    display: initial;
}

.uploadAvatar:hover .normalContent {
    display: none;
}