.listContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0px 4px;
}

.emptyListText {
    color: #a1a1a1;
    font-size: 16px;
    text-align: center;
}