 .opporunityForm {
     display: flex;
     flex-direction: column;
     margin: 10px 0px;
     align-items: flex-start;
 }

 .opporunityForm>* {
     width: 100%;
 }

 .grouping {
     padding: 5px;
     margin: 5px 0px;
     display: flex;
     align-items: center;
 }